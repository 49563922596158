import {Injectable} from '@angular/core';
import {Observable} from 'rxjs/Observable';

import {
  BaseCrudService,
  DateService,
  EnvironmentService,
  HttpService,
  ListService,
  LocalStorageService,
  LoginService,
  Request,
  RequestService,
  Response,
  StringService,
  UserType,
  VariableService
} from '@uab.lakeshore.collaborative/next-base';

@Injectable()
export class UserTypeService extends BaseCrudService {
  public model = 'user_types';

  constructor(
    private date:DateService,
    private http:HttpService,
    private variable:VariableService,
    private environment:EnvironmentService,
    private local:LocalStorageService,
    private login:LoginService,
    private list:ListService,
    private request:RequestService,
    private string:StringService
  ) {
    super(
      variable,
      environment,
      list,
      request,
      string
    );
  }

  createUserType(newUserType:UserType):Observable<Response> {
    newUserType.display_name = this.variable.get(
      newUserType.display_name,
      newUserType.name
    );

    return super._create(newUserType);
  }

  deleteUserType(userType:UserType):Observable<Response> {
    return super._delete(userType);
  }

  editUserType(userType:UserType):Observable<Response> {
    userType.display_name = this.variable.getString(
      userType.name
    );

    return super._edit(userType);
  }

  getColumns(request?:Request):Observable<Response> {
    return super._getColumns(request);
  }

  getMore(request?:Request):Observable<Response> {
    return super._getMore(request);
  }

  getPage(request?:Request):Observable<Response> {
    return super._getPage(request);
  }

  getUserType(request?:Request):Observable<Response> {
    return super._get(request);
  }
}
