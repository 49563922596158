<ion-card (click)="goToUser()"
          *ngIf="isLoggedIn && !isLoadingUser"
          class="show-hand-cursor">
  <ion-item>
    <ion-avatar slot="start">
      <div [innerHTML]="getUserImage(user) | safeHtml"></div>
    </ion-avatar>

    <ion-label class="ion-text-wrap">
      <ion-title style="padding: 0 20px; text-align: start">{{ user.display_name }}</ion-title>
    </ion-label>
  </ion-item>
</ion-card>

<ion-card *ngIf="isLoggedIn && isLoadingUser">
  <ion-item>
    <ion-avatar slot="start">
      <uab-skeleton-block></uab-skeleton-block>
    </ion-avatar>

    <ion-label class="height-60">
      <uab-skeleton-block></uab-skeleton-block>
    </ion-label>
  </ion-item>
</ion-card>
