import {Injectable} from '@angular/core';

import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs';

import {
  BaseCrudService,
  EnvironmentService,
  ListService,
  Request,
  RequestService,
  Response,
  StringService,
  VariableService
} from '@uab.lakeshore.collaborative/next-base';

@Injectable()
export class UserStatusService extends BaseCrudService {
  public model = 'user_statuses';

  constructor(
    private environment:EnvironmentService,
    private list:ListService,
    private request:RequestService,
    private string:StringService,
    private variable:VariableService,
  ) {
    super(
      variable,
      environment,
      list,
      request,
      string
    );
  }

  getColumns(request?:Request):Observable<Response> {
    return of(new Response());
  }

  getStatuses(request):Observable<Response> {
    request = this.request.getRequest(request);

    request = this.request.setLimit(request, 100);

    return super._get(request);
  }

  getMore(request?:Request):Observable<Response> {
    return this.getStatuses(request);
  }

  getPage(request?:Request):Observable<Response> {
    return this.getStatuses(request);
  }
}
