import {Component} from '@angular/core';

import {ModalController, NavParams} from '@ionic/angular';

import {BaseComponent, VariableService} from '@uab.lakeshore.collaborative/next-base';

@Component({
  selector: 'uab-confirmation',
  templateUrl: 'confirmation.component.html',
  styleUrls: [
    'confirmation.component.scss'
  ]
})
export class ConfirmationComponent implements BaseComponent {
  public columns;

  public cancelText:string;
  public confirmText:string;
  public confirmTitleText:string;
  public message:string;

  constructor(
    private variableService:VariableService,
    private modalCtrl:ModalController,
    private navParams:NavParams
  ) {
    this.reset();
  }

  reset() {
    this.cancelText = this.navParams.get('cancelText');
    this.confirmText = this.navParams.get('confirmText');
    this.confirmTitleText = this.navParams.get('confirmTitleText');
    this.message = this.navParams.get('message');
  }

  cancel() {
    this.dismiss(false).then();
  }

  confirm() {
    this.dismiss(true).then();
  }

  async dismiss(response) {
    await this.modalCtrl.dismiss(response);
  }
}
