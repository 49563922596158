import {Component, EventEmitter, Input, Output} from '@angular/core';

import {
  BaseCountSocketInterface,
  FeatureUserType,
  LoginService,
  NotificationCountSocket,
  RedirectService,
  RouteService,
  StringService,
  VariableService
} from '@uab.lakeshore.collaborative/next-base';
import {InboxCountSocket} from '../../sockets/count/inbox-count.socket';
import {ConfirmationComponentController} from '../confirmation/confirmation.component.controller';

@Component({
  selector: 'uab-menu-feature-button',
  templateUrl: 'menu-feature-button.component.html',
  styleUrls: [
    'menu-feature-button.component.scss'
  ]
})
export class MenuFeatureButtonComponent {
  public buttonColor:string = 'secondary-color';
  public id:string;
  public isActivePage:boolean = false;
  public model:FeatureUserType = new FeatureUserType();
  public socketCount:number = 0;

  @Input() enableRoutes:boolean = true;
  @Input() showDescription:boolean = false;
  @Input() showFullName:boolean = false;
  @Output() onClick:EventEmitter<FeatureUserType>;

  constructor(
    private confirm:ConfirmationComponentController,
    private inboxSocket:InboxCountSocket,
    private login:LoginService,
    private notificationSocket:NotificationCountSocket,
    private redirect:RedirectService,
    private route:RouteService,
    private string:StringService,
    private variable:VariableService
  ) {
    this.onClick = new EventEmitter<FeatureUserType>();
  }

  @Input()
  set featureUserType(featureUserType:FeatureUserType) {
    if (this.variable.isDefined(featureUserType)) {
      this.setModel(featureUserType);
    }
  }

  click():void {
    if (this.enableRoutes && this.variable.isDefined(this.model.sref)) {
      if (this.model.sref === 'logout') {
        this.confirm.confirm('Are you sure you want to logout?').then(
          (confirm) => {
            if (confirm) {
              this.login.logout().subscribe();
            }
          }
        );
      } else {
        this.redirect.goTo(
          this.model.sref
        );
      }
    }

    this.onClick.emit(this.model);
  }

  listen():void {
    const name = this.variable.getString(
      this.model.socket
    ).toLowerCase();

    let socket:BaseCountSocketInterface;
    if (name === 'inbox') {
      socket = this.inboxSocket;
    } else if (name === 'notification') {
      socket = this.notificationSocket;
    } else {
      return;
    }

    socket.onCount(
      (data:any) => {
        this.socketCount = this.variable.getInteger(
          data.count,
          0
        );
      }
    );
  }

  getId():string {
    let id = '';

    if (this.variable.isObject(this.model.features)) {
      const feature = this.model.features;
      if (this.variable.isString((<any>feature).key)) {
        id = (<any>feature).key.toLowerCase();
      } else if (this.variable.isString(feature.name)) {
        id = this.string.toUnderScore((<any>feature).name.toLowerCase());
      }
    }

    if (id === '') {
      if (this.variable.isString(this.model.sref)) {
        id = this.model.sref.toLowerCase();
      } else {
        id = this.string.toUnderScore(this.model.name.toLowerCase());
      }
    }

    return id;
  }

  setActivePage():void {
    this.route.url.subscribe(
      (data) => {
        data = this.variable.getArray(data);

        if (data.length !== 0) {
          const route = data[0].path.replace('/', '');

          this.isActivePage = route === this.model.sref;
        }
      }
    );
  }

  setModel(featureUserType:FeatureUserType):void {
    this.model = featureUserType;
    this.id = this.getId();

    if (this.model.socket !== null) {
      this.listen();
    }
  }
}
