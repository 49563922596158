import {Component} from '@angular/core';

import {Platform} from '@ionic/angular';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {
  AccessibilityService,
  ActiveUser,
  AnalyticStateVisitService,
  FeatureUserType,
  LoginService,
  PlatformService,
  RegistryService,
  Request,
  RequestService,
  RouteService,
  SplashScreenService,
  UserTypes
} from '@uab.lakeshore.collaborative/next-base';

@Component({
  selector: 'uab-root',
  templateUrl: 'app.component.html',
})
export class AppComponent {
  public activeUser:ActiveUser | boolean;
  public isAdmin:boolean;
  public isLoadingFeatures:boolean = true;
  public isLoadingActiveUser:boolean;
  public isLoadingSections:boolean;
  public isLoggedIn:boolean;
  public showSplitPane:boolean = false;
  public request:Request;

  public features:FeatureUserType[];

  constructor(
    private accessibility:AccessibilityService,
    private analytics:AnalyticStateVisitService,
    private loginService:LoginService,
    private platformService:PlatformService,
    private platform:Platform,
    private registry:RegistryService,
    private requestService:RequestService,
    private route:RouteService,
    private splashService:SplashScreenService,
    private statusBar:StatusBar
  ) {
    this.analytics.bootstrap().subscribe();

    this.loginService.registerLoggingIn(
      () => {
        this.reset();

        this.initializeApp();

        this.splashService.show('app-component-logging-in');
      }
    );

    this.loginService.registerLoginFailure(
      () => {
        this.splashService.hide('app-component-logging-in');
      }
    );

    this.loginService.registerLogin(
      () => {
        this.loadActiveUser();

        this.splashService.hide('app-component-logging-in');
      }
    );

    this.reset();
    this.initializeApp();
  }

  completeFeatures() {
    this.isLoadingFeatures = false;

    this.toggleSplitPane();
  }

  toggleSplitPane():void {
    this.showSplitPane = !this.showSplitPane;
  }

  initializeApp():void {
    this.splashService.show('app-component-initializing');

    this.platform.ready().then(
      () => {
        if (this.platformService.isApplication()) {
          this.statusBar.styleDefault();
        }

        this.accessibility.loadAccessibilitySettings();

        if (this.loginService.isLoggedIn()) {
          this.loadActiveUser();
        }

        this.splashService.hide('app-component-initializing');
      }
    );
  }

  loadActiveUser():void {
    this.isLoadingActiveUser = true;

    const activeUser = this.loginService.getActiveUser();
    if (activeUser) {
      this.activeUser = <ActiveUser><unknown>activeUser;
      this.isAdmin = (<ActiveUser>this.activeUser).user_type_name.toLowerCase().indexOf(UserTypes.ADMIN) !== -1;

      this.isLoggedIn = true;
    }
  }

  reset() {
    this.activeUser = false;

    this.isLoadingActiveUser = false;

    this.isLoadingSections = false;

    this.isLoggedIn = false;

    this.resetRequest();
  }

  resetRequest() {
    this.request = this.requestService.getRequest();

    this.request = this.requestService.setJoins(
      this.request,
      [
        '>features'
      ]
    );

    this.request = this.requestService.setLimit(
      this.request,
      100
    );
  }
}
