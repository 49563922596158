import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import {MenuFeatureButtonComponent} from './menu-feature-button.component';
import {InboxCountSocket} from '../../sockets/count/inbox-count.socket';
import {NotificationCountSocket} from '@uab.lakeshore.collaborative/next-base';
import {ConfirmationModule} from "../confirmation/confirmation.module";

@NgModule({
  imports: [
    CommonModule,
    ConfirmationModule,
    FormsModule,
    IonicModule,
    RouterModule
  ],
  exports: [
    MenuFeatureButtonComponent
  ],
  declarations: [
    MenuFeatureButtonComponent
  ],
  providers: [
    InboxCountSocket,
    NotificationCountSocket
  ]
})
export class MenuFeatureButtonModule {
}
