import {NgModule} from '@angular/core';

import {RouterModule, Routes} from '@angular/router';

import {IsAtLeastAdminGuard} from './guards/is-at-least-admin.guard';
import {LoginGuard, LogoutGuard} from '@uab.lakeshore.collaborative/next-base';

const routes:Routes = [
  {
    path: 'forgot-password',
    loadChildren:
      () => import(
        './pages/user-forgot-password/user-forgot-password.module'
        ).then(
        m => m.UserForgotPasswordModule
      ),
  },
  {
    path: 'home',
    loadChildren:
      () => import(
        './pages/home/home.module'
        ).then(
        m => m.HomePageModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'login',
    loadChildren:
      () => import(
        './pages/login/login.module'
        ).then(
        m => m.LoginPageModule
      ),
    data: {
      username: '',
      password: ''
    },
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'logout',
    loadChildren:
      () => import(
        './pages/logout/logout.module'
        ).then(
        m => m.LogoutPageModule
      ),
    data: {
      username: '',
      password: ''
    },
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'login-attempt-management',
    loadChildren:
      () => import(
        './pages/data-tables/login-attempt-management/login-attempt-management.module'
        ).then(
        m => m.LoginAttemptManagementPageModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'not-found',
    loadChildren:
      () => import(
        './pages/not-found/not-found.module'
        ).then(
        m => m.NotFoundPageModule
      )
  },
  {
    path: 'security-question-create',
    loadChildren:
      () => import(
        './pages/security-question-create/security-question-create.module'
        ).then(
        m => m.SecurityQuestionCreatePageModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'security-question-edit/:securityQuestionId',
    loadChildren:
      () => import(
        './pages/security-question-edit/security-question-edit.module'
        ).then(
        m => m.SecurityQuestionEditPageModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'set-password/:userId',
    loadChildren:
      () => import(
        './pages/set-password/set-password.module'
        ).then(
        m => m.SetPasswordPageModule
      ),
    canActivate: [
      LogoutGuard
    ]
  },
  {
    path: 'settings',
    loadChildren:
      () => import(
        './pages/settings/settings.page.module'
        ).then(
        m => m.SettingsPageModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'security-question-management',
    loadChildren:
      () => import(
        './pages/data-tables/security-question-management/security-question-management.module'
        ).then(
        m => m.SecurityQuestionManagementPageModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'sql-log-management',
    loadChildren:
      () => import(
        './pages/data-tables/sql-log-management/sql-log-management.module'
        ).then(
        m => m.SqlLogManagementPageModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'state-analytic-management',
    loadChildren:
      () => import(
        './pages/data-tables/state-analytic-management/state-analytic-management.module'
        ).then(
        m => m.StateAnalyticManagementPageModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'survey/:surveyId',
    loadChildren:
      () => import(
        './pages/survey/survey.module'
        ).then(
        m => m.SurveyPageModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'user-analytic-management',
    loadChildren:
      () => import(
        './pages/data-tables/user-analytic-management/user-analytic-management.module'
        ).then(
        m => m.UserAnalyticManagementPageModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'user-create',
    loadChildren:
      () => import(
        './pages/user-create/user-create.module'
        ).then(
        m => m.UserCreatePageModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'user-edit/:userId',
    loadChildren:
      () => import(
        './pages/user-edit/user-edit.module'
        ).then(
        m => m.UserEditPageModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'user-management',
    loadChildren:
      () => import(
        './pages/data-tables/user-management/user-management.module'
        ).then(
        m => m.UserManagementPageModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'user-type-create',
    loadChildren:
      () => import(
        './pages/user-type-create/user-type-create.module'
        ).then(
        m => m.UserTypeCreateModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'user-type-edit/:userTypeId',
    loadChildren:
      () => import(
        './pages/user-type-edit/user-type-edit.module'
        ).then(
        m => m.UserTypeEditPageModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'user-type-management',
    loadChildren:
      () => import(
        './pages/data-tables/user-type-management/user-type-management.module'
        ).then(
        m => m.UserTypeManagementPageModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'video/:videoId',
    loadChildren:
      () => import(
        './pages/video/video.module'
        ).then(
        m => m.VideoPageModule
      ),
    canActivate: [
      LoginGuard
    ]
  },
  {
    path: 'version',
    loadChildren:
      () => import(
        './pages/version/version.module'
        ).then(
        m => m.VersionPageModule
      ),
  },
  {
    path: 'video-create',
    loadChildren:
      () => import(
        './pages/video-create/video-create.module'
        ).then(
        m => m.VideoCreatePageModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'video-edit/:videoId',
    loadChildren:
      () => import(
        './pages/video-edit/video-edit.module'
        ).then(
        m => m.VideoEditPageModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'video-list',
    loadChildren:
      () => import(
        './pages/video-list/video-list.module'
        ).then(
        m => m.VideoListPageModule
      ),
    canActivate: [
      LoginGuard
    ],
  },
  {
    path: 'video-management',
    loadChildren:
      () => import(
        './pages/data-tables/video-management/video-management.module'
        ).then(
        m => m.VideoManagementPageModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'video-analytic-management',
    loadChildren:
      () => import(
        './pages/data-tables/video-analytic-management/video-analytic-management.module'
        ).then(
        m => m.VideoAnalyticManagementPageModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'zoom-meeting-management',
    loadChildren:
      () => import(
        './pages/data-tables/zoom-meeting-management/zoom-meeting-management.module'
        ).then(
        m => m.ZoomMeetingManagementPageModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'zoom-meeting-create',
    loadChildren:
      () => import(
        './pages/zoom-meeting-create/zoom-meeting-create.module'
        ).then(
        m => m.ZoomMeetingCreatePageModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: 'zoom-meeting-edit/:zoomMeetingId',
    loadChildren:
      () => import(
        './pages/zoom-meeting-edit/zoom-meeting-edit.module'
        ).then(
        m => m.ZoomMeetingEditPageModule
      ),
    canActivate: [
      IsAtLeastAdminGuard,
      LoginGuard
    ]
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'login',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {enableTracing: false, useHash: true, relativeLinkResolution: 'legacy'}
    )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
