import {Injectable} from '@angular/core';

import {
  BaseCountSocket,
  BaseCountSocketInterface,
  EnvironmentService,
  HttpService,
  LoginService,
  ObjectService,
  RequestService
} from '@uab.lakeshore.collaborative/next-base';

@Injectable()
export class InboxCountSocket extends BaseCountSocket implements BaseCountSocketInterface {
  constructor(
    private environment:EnvironmentService,
    private http:HttpService,
    private login:LoginService,
    private object:ObjectService,
    private request:RequestService
  ) {
    super(
      'socket.io',
      environment,
      http,
      login,
      object,
      request
    );

    this.resetRequest();
  }

  onCount(callback:Function):void {
    this.on(
      'notifications.' + this.activeUser.id + ':InboxCount',
      callback
    );
  }

  onSend(callback:Function):void {
    this.on(
      'notifications.' + this.activeUser.id + ':InboxSent',
      callback
    );
  }

  resetRequest() {
    this.requestObject = this.request.setPrefix(
      this.requestObject,
      'socket'
    );

    this.requestObject = this.request.setModel(
      this.requestObject,
      'inbox-count'
    );
  }
}
