import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';

import {
  SafeHtmlModule,
  SkeletonBlockModule,
  SkeletonHeaderModule,
  SkeletonParagraphModule
} from '@uab.lakeshore.collaborative/next-base';

import {UserNameCardComponent} from './user-name-card.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SkeletonBlockModule,
    SkeletonHeaderModule,
    SkeletonParagraphModule,
    SafeHtmlModule
  ],
  exports: [
    UserNameCardComponent
  ],
  declarations: [
    UserNameCardComponent
  ]
})
export class UserNameCardModule {
}
