import {Injectable} from '@angular/core';

import {ActionSheetController, ModalController} from '@ionic/angular';

import {LingoService, VariableService} from '@uab.lakeshore.collaborative/next-base';

import {ConfirmationComponent} from './confirmation.component';

@Injectable()
export class ConfirmationComponentController {
  public cancelText:string;
  public confirmText:string;
  public confirmTitleText:string;

  constructor(
    private actionSheetCtrl:ActionSheetController,
    private variableService:VariableService,
    private modalCtrl:ModalController,
    private lingo:LingoService
  ) {
    this.loadLingo();
  }

  async confirm(message:string):Promise<boolean> {
    const options:any = {
      component: ConfirmationComponent,
      componentProps: {
        cancelText: this.cancelText,
        confirmText: this.confirmText,
        confirmTitleText: this.confirmTitleText,
        message: message,
      },
      cssClass: 'select-modal'
    };

    const modal = await this.modalCtrl.create(options);

    const returnVar = modal.onDidDismiss().then(
      (detail) => {
        if (detail.data) {
          return detail.data;
        }

        return false;
      }
    );

    await modal.present();

    return returnVar;
  }

  loadLingo():void {
    this.cancelText = this.lingo.get(
      'confirmation.cancel',
      'Cancel'
    );

    this.confirmText = this.lingo.get(
      'confirmation.confirm',
      'Confirm'
    );

    this.confirmTitleText = this.lingo.get(
      'confirmation.confirm-title',
      'Confirmation'
    );
  }
}
