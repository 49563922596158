<ion-header id="confirmation-modal-header">
  <ion-title>
    {{ confirmTitleText }}
  </ion-title>
</ion-header>

<ion-content id="confirmation-modal-content">
  <div id="confirmation-modal-message">
    {{ message }}
  </div>

  <ion-row>
    <ion-col [size]="6">
      <ion-button (click)="cancel()"
                  [color]="'light'"
                  [fill]="'outline'"
                  [size]="'full'"
                  class="decline-button"
                  id="confirmation-decline-button">
        {{ cancelText }}
      </ion-button>
    </ion-col>

    <ion-col [size]="6">
      <ion-button (click)="confirm()"
                  [color]="'light'"
                  [fill]="'outline'"
                  [size]="'full'"
                  class="accept-button"
                  id="confirmation-accept-button">
        {{ confirmText }}
      </ion-button>
    </ion-col>
  </ion-row>
</ion-content>