export const environment = {
  environment: 'prod',
  frontendUrl: 'https://livetest.m2mstudy.org',
  backendUrl: 'https://livetest.m2mstudy.org/laravel',
  socketUrl: 'https://app.scipe.org:3000',
  splashScreen: 'folding-cube',
  production: false,
  project: 'drrp',
  sentryRateLimitCount: 3,
  sentryRateLimitSeconds: 3,
  version: '0.6.0'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
import 'zone.js/plugins/zone-error';