import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';

import {ConfirmationComponent} from './confirmation.component';
import {ConfirmationComponentController} from './confirmation.component.controller';

@NgModule({
  declarations: [
    ConfirmationComponent
  ],
  entryComponents: [
    ConfirmationComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
  ],
  providers: [
    ConfirmationComponentController
  ],
})
export class ConfirmationModule {
}
