import {Injectable} from '@angular/core';

import {DateService, UserInterface, VariableService} from '@uab.lakeshore.collaborative/next-base';

import * as moment from 'moment';
import Diff = moment.unitOfTime.Diff;

@Injectable()
export class UserDurationService {
  constructor(
    private dateService:DateService,
    private variable:VariableService
  ) {

  }

  public getDays(user:UserInterface):number {
    return this.get(user, 'days');
  }

  public getHours(user:UserInterface):number {
    return this.get(user, 'hours');
  }

  public getMilliSeconds(user:UserInterface):number {
    return this.get(user, 'milliseconds');
  }

  public getMinutes(user:UserInterface):number {
    return this.get(user, 'minutes');
  }

  public getMonths(user:UserInterface):number {
    return this.get(user, 'months');
  }

  public getSeconds(user:UserInterface):number {
    return this.get(user, 'seconds');
  }

  public getWeeks(user:UserInterface):number {
    return this.get(user, 'weeks');
  }

  public getYears(user:UserInterface):number {
    return this.get(user, 'years');
  }

  private getUserDuration(user?:UserInterface, type?:Diff):number {
    if (this.variable.isObject(user)) {
      if (this.variable.isDefined(user.start_time)) {
        const startDate = this.dateService.moment(user.start_time);

        if (startDate) {
          const endDate = this.dateService.moment();

          return endDate.diff(startDate, type);
        }
      }
    }

    return 0;
  }

  private get(user?, type?) {
    const value = this.getUserDuration(user, type);

    return value + 1;
  }
}
