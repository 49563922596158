import {Injectable} from '@angular/core';

import {Router} from '@angular/router';

import {Observable} from 'rxjs';

import {
  EnvironmentService,
  FeatureUserType,
  HasGet,
  HttpService,
  ListService,
  OfflineService,
  Request,
  RequestService,
  Response,
  VariableService
} from '@uab.lakeshore.collaborative/next-base';

@Injectable()
export class FeatureUserTypeService implements HasGet {
  constructor(
    private variableService:VariableService,
    private environment:EnvironmentService,
    private http:HttpService,
    private list:ListService,
    private offline:OfflineService,
    private requestService:RequestService,
    private router:Router
  ) {

  }

  // TODO: Jeremy
  // addRoutes(activeUser:ActiveUser):Observable<void> {
  //   if (this.variableService.isObject(activeUser) && this.variableService.isObject(activeUser.user_types)) {
  //     return of(true).pipe(
  //       map(
  //         () => {
  //           this.router.config.push(
  //             {
  //               path: 'home',
  //               loadChildren:
  //                 () => import(
  //                   '../pages/home/home.module'
  //                   ).then(
  //                   m => m.HomePageModule
  //                 ),
  //               canActivate: [
  //                 LoginService
  //               ]
  //             }
  //           );
  //
  //           this.router.config = this.router.config.filter(
  //             (route) => {
  //               return route.path !== '' && route.path !== '**';
  //             }
  //           );
  //
  //           this.router.config.push(
  //             {
  //               path: '',
  //               redirectTo: 'login',
  //               pathMatch: 'full'
  //             },
  //             {
  //               path: '**',
  //               redirectTo: 'login',
  //               pathMatch: 'full'
  //             }
  //           );
  //         }
  //       )
  //     );
  //   } else {
  //     return of();
  //   }
  // }

  buildOptions(request?:Request):Request {
    request = this.requestService.getRequest(request);

    request = this.requestService.setModel(request, 'features_user_types');
    request = this.requestService.setAction(request, 'get');

    request = this.requestService.setOrder(request, 'position');

    request = this.requestService.setUseCache(request, true);

    return request;
  }

  flush():boolean {
    return this.list.flush('features_user_types') !== 0;
  }

  getMore(request?:Request):Observable<Response> {
    request = this.buildOptions(request);

    return this.list.getMore(request);
  }

  getPage(request?:Request):Observable<Response> {
    request = this.buildOptions(request);

    return this.list.getPage(request);
  }

  createFeatureUserType(newFeatureUserType?:FeatureUserType):Observable<Response> {
    let request = this.requestService.getRequest();

    request = this.requestService.setModel(request, 'features_user_types');
    request = this.requestService.setAction(request, 'create');

    request = this.requestService.setData(request, newFeatureUserType);

    return this.list.create(request);
  }

  deleteFeatureUserType(featureUserType:FeatureUserType):Observable<Response> {
    let request = this.requestService.getRequest();

    request = this.requestService.setModel(request, 'features_user_types');
    request = this.requestService.setAction(request, 'delete', featureUserType.id);

    return this.list.remove(request);
  }

  editFeatureUserType(featureUserType:FeatureUserType):Observable<Response> {
    let request = this.requestService.getRequest();

    request = this.requestService.setModel(request, 'features_user_types');
    request = this.requestService.setAction(request, 'edit', featureUserType.id);

    request = this.requestService.setData(request, featureUserType);

    return this.list.edit(request);
  }

  editFeatureUserTypes(featureUserTypes:FeatureUserType[]):Observable<Response> {
    let request = this.requestService.getRequest();

    request = this.requestService.setModel(request, 'features_user_types');
    request = this.requestService.setAction(request, 'edit', 'bulk');

    request = this.requestService.setData(request, featureUserTypes);

    return this.list.edit(request);
  }
}
