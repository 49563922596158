import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';

import {MenuListComponent} from './menu-list.component';

import {
  EnvironmentService,
  ListEndCapModule,
  RangeModule,
  SkeletonBlockModule
} from '@uab.lakeshore.collaborative/next-base';
import {MenuFeatureButtonModule} from '../menu-feature-button/menu-feature-button.module';
import {UserNameCardModule} from '../user-name-card/user-name-card.module';

import {FeatureUserTypeService} from '../../services/feature-user-type.service';
import {ConfirmationModule} from "../confirmation/confirmation.module";

@NgModule({
  declarations: [
    MenuListComponent
  ],
  exports: [
    MenuListComponent
  ],
  imports: [
    CommonModule,
    ConfirmationModule,
    FormsModule,
    IonicModule,
    ListEndCapModule,
    MenuFeatureButtonModule,
    RangeModule,
    SkeletonBlockModule,
    UserNameCardModule
  ],
  providers: [
    EnvironmentService,
    FeatureUserTypeService
  ]
})
export class MenuListModule {
}
