import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';

import {ActiveUser, AlertService, LoginService, User, UserTypes} from '@uab.lakeshore.collaborative/next-base';

@Injectable()
export class IsAtLeastAdminGuard implements CanActivate {
  constructor(
    private alert:AlertService,
    private login:LoginService
  ) {

  }

  canActivate():boolean {
    let isAtLeastAdmin = false;

    const activeUser = <ActiveUser><unknown>this.login.getActiveUser();
    if (activeUser) {
      isAtLeastAdmin = (<User>activeUser).user_type_name === UserTypes.SUPER_ADMIN
        || (<User>activeUser).user_type_name === UserTypes.ADMIN;
    }

    if (isAtLeastAdmin === false) {
      this.alert.addError('Only admins can access this page.');
    }

    return isAtLeastAdmin;
  }
}
