<div [id]="id"
     class="menu-button">
  <div #rla="routerLinkActive"
       (click)="click()"
       (mouseout)="buttonColor='secondary-color'"
       (mouseover)="buttonColor='primary-color'"
       [ngClass]=" {
          buttonColor: enableRoutes && !rla.isActive,
          'active-menu-item-color': enableRoutes && rla.isActive
       }"
       [routerLinkActiveOptions]="{ exact: true }"
       [routerLinkActive]
       [routerLink]="model.sref"
       class="show-hand-cursor menu-feature-button menu-feature-button-height-default">
    <ion-icon [src]="'assets/icons/' + model.icon + '.svg'"
              class="menu-feature-button-icon"></ion-icon>

    <div class="menu-feature-button-name-default button-name-font">
      {{ model.name ? model.name : model.features.name }}
      <span *ngIf="showFullName && model.features">
        &nbsp;({{ model.features.name }})
      </span>
    </div>

    <div *ngIf="model.features && showDescription"
         class="menu-feature-button-description ion-text-wrap">
      {{ model.features.description }}
    </div>

    <ion-badge *ngIf="!showDescription && model.socket !== null"
               [color]="socketCount === 0 ? 'medium' : 'danger'"
               style="color: white; font-size: large; position: absolute; right: 10px; top: 10px; height: 30px; width: 30px;">
      {{ socketCount }}
    </ion-badge>
  </div>
</div>
