<ion-app class="global-width">
  <ion-split-pane [when]="isAdmin && isLoggedIn && showSplitPane && '(min-width: 992px)'" contentId="menu-content">
    <ion-menu [hidden]="!isLoggedIn"
              class="do-not-print"
              contentId="menu-content"
              id="main-menu">
      <ion-content>
        <uab-menu-list (onComplete)="completeFeatures()"></uab-menu-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="menu-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>

<uab-splash-screen></uab-splash-screen>
<uab-logging-out-modal></uab-logging-out-modal>
<uab-browser-block></uab-browser-block>
